import { EndCourseValidationStatus } from "../constants/endCourseValidationStatus";

export const getErrorCategoryColor = (validationStatus: EndCourseValidationStatus) =>
  validationStatus === EndCourseValidationStatus.Error
    ? "error"
    : validationStatus === EndCourseValidationStatus.Warning
    ? "primary"
    : "success";

export const getErrorCategoryIcon = (validationStatus: EndCourseValidationStatus) =>
  validationStatus === EndCourseValidationStatus.Error
    ? "mdi-close-circle"
    : validationStatus === EndCourseValidationStatus.Warning
    ? "mdi-alert-circle"
    : "mdi-check-circle";
