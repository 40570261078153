var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.contentReady ? _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("Avslutt - " + _vm._s(_vm.course.courseName))];
      },
      proxy: true
    }], null, false, 318254914)
  }, [[_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('CourseCard', {
    staticClass: "mb-4",
    attrs: {
      "course": _vm.course,
      "isCalendarValid": true,
      "curriculum": _vm.curriculum,
      "maxHours": _vm.curriculum.hoursMax,
      "isVocationalSchool": _vm.isVocationalSchool
    }
  }), _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-expansion-panels', {
    attrs: {
      "accordion": "",
      "flat": "",
      "outline": ""
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "px-4 py-2"
  }, [_c('span', {
    staticClass: "subtitle-1"
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.getErrorCategoryColor(_vm.closeParticipantErrors.length ? _vm.EndCourseValidationStatus.Warning : _vm.EndCourseValidationStatus.Success),
      "left": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.getErrorCategoryIcon(_vm.closeParticipantErrors.length ? _vm.EndCourseValidationStatus.Warning : _vm.EndCourseValidationStatus.Success))
    }
  }), _vm._v("Avslutt deltaker")], 1)]), _c('v-expansion-panel-content', [_c('v-list', {
    attrs: {
      "subheader": ""
    }
  }, [_vm._l(_vm.closeParticipantErrors, function (errorItem, i) {
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "color": "primary"
      }
    }, [_vm._v("mdi-alert-box")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "capitalize"
    }, [_vm._v(_vm._s(errorItem))])], 1)], 1);
  }), _vm.closeParticipantErrors.length === 0 ? _c('v-subheader', [_vm._v("Alle påmeldte har endret status")]) : _vm._e()], 2)], 1)], 1), _c('EndCourseExpansionPanel', {
    attrs: {
      "title": "Kurs/studie",
      "btnText": "Gå til",
      "category": _vm.getCategoryErrors(_vm.EndCourseValidationCategories.Course),
      "successText": "Det er ikke avvik mellom antall timer med lærer og antall timer i timeplanoppføringer"
    },
    on: {
      "onListItemClick": _vm.navigateToParticipant
    }
  }), _vm.hasRegisterHours ? _c('EndCourseExpansionPanel', {
    attrs: {
      "title": "Fremmøte",
      "btnText": "Gå til",
      "successText": "Fremmøteregistreringen er komplett",
      "category": _vm.getCategoryErrors(_vm.EndCourseValidationCategories.ParticipantAttendance)
    },
    on: {
      "onListItemClick": _vm.navigateToParticipant
    }
  }) : _vm._e(), _vm.hasSchedule ? _c('EndCourseExpansionPanel', {
    attrs: {
      "title": "Arbeidstimer",
      "btnText": "Gå til",
      "successText": "Alle arbeidstimer er ajourført",
      "category": _vm.getCategoryErrors(_vm.EndCourseValidationCategories.Workinghour)
    },
    on: {
      "onListItemClick": _vm.navigateToParticipant
    },
    scopedSlots: _vm._u([{
      key: "byPassValidation",
      fn: function fn() {
        return [_vm.hasWorkingHoursBypass ? _c('v-checkbox', {
          attrs: {
            "label": "Arbeidstimer er utbetalt fra andre"
          },
          model: {
            value: _vm.skipWorkingHours,
            callback: function callback($$v) {
              _vm.skipWorkingHours = $$v;
            },
            expression: "skipWorkingHours"
          }
        }) : _vm._e()];
      },
      proxy: true
    }], null, false, 1743448785)
  }) : _vm._e(), _c('EndCourseExpansionPanel', {
    attrs: {
      "title": "Økonomi",
      "isUserButton": false,
      "successText": "Alle ordrer er ajourført",
      "category": _vm.getCategoryErrors(_vm.EndCourseValidationCategories.Economy)
    },
    on: {
      "onListItemClick": _vm.navigateToParticipant
    }
  }), _vm.allowExpenses ? _c('EndCourseExpansionPanel', {
    attrs: {
      "title": "Refusjonskrav",
      "isUserButton": false,
      "successText": "Alle refusjonskrav er ajourført",
      "category": _vm.getCategoryErrors(_vm.EndCourseValidationCategories.ParticipantExpense)
    },
    on: {
      "onListItemClick": _vm.navigateToParticipant
    }
  }) : _vm._e(), _vm.hasTrainingGrant ? _c('EndCourseExpansionPanel', {
    attrs: {
      "title": "Opplæringstilskudd",
      "category": _vm.getCategoryErrors(_vm.EndCourseValidationCategories.TeachingGrant),
      "btnText": "Gå til",
      "successText": "Alle krav er møtt"
    },
    on: {
      "onListItemClick": _vm.navigateToParticipant
    },
    scopedSlots: _vm._u([{
      key: "byPassValidation",
      fn: function fn() {
        return [_vm.hasAmountOfParticipantsOlderThan14Bypass ? _c('v-checkbox', {
          attrs: {
            "label": "Gitt dispensasjon for færre enn 4 deltakere over 14 år"
          },
          model: {
            value: _vm.skipAmountOfParticipantsOlderThan14,
            callback: function callback($$v) {
              _vm.skipAmountOfParticipantsOlderThan14 = $$v;
            },
            expression: "skipAmountOfParticipantsOlderThan14"
          }
        }) : _vm._e(), _vm.hasTeachingHoursBypass ? _c('v-checkbox', {
          attrs: {
            "label": "Gitt dispensasjon for færre enn 4 timer med lærer"
          },
          model: {
            value: _vm.skipTeacherHours,
            callback: function callback($$v) {
              _vm.skipTeacherHours = $$v;
            },
            expression: "skipTeacherHours"
          }
        }) : _vm._e()];
      },
      proxy: true
    }], null, false, 1222971830)
  }) : _vm._e()], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.closeCourse
    }
  }, [_vm._v(" Avslutt ")])], 1)], 1)], 1)]], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }