var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expansion-panel', {
    attrs: {
      "disabled": !_vm.hasWarningOrError && !_vm.successText
    }
  }, [_c('v-expansion-panel-header', {
    staticClass: "px-4 py-2"
  }, [_c('span', {
    staticClass: "subtitle-1"
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.getErrorCategoryColor(_vm.courseValidationStatus),
      "left": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.getErrorCategoryIcon(_vm.courseValidationStatus))
    }
  }), _vm._v(_vm._s(_vm.title))], 1)]), _vm.errors.length || _vm.warnings.length || _vm.successText ? _c('v-expansion-panel-content', [_c('v-list', {
    attrs: {
      "subheader": ""
    }
  }, [!_vm.hasWarningOrError && _vm.successText ? _c('v-subheader', [_vm._v(_vm._s(_vm.successText))]) : _vm._e(), _vm._t("byPassValidation"), _vm.errors.length ? _c('v-subheader', [_vm._v("Må rettes")]) : _vm._e(), _vm._l(_vm.errors, function (errorItem, i) {
    return _c('v-list-item', {
      key: i + 1
    }, [_c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "color": "error"
      }
    }, [_vm._v("mdi-close-circle")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "capitalize"
    }, [_vm._v(_vm._s(errorItem.message))]), _vm.isUserButton ? _c('v-list-item-subtitle', {
      staticClass: "d-flex flex-row"
    }, _vm._l(errorItem.users, function (user, i) {
      return _c('v-btn', {
        key: i,
        staticClass: "mr-2",
        attrs: {
          "small": "",
          "color": "error"
        },
        domProps: {
          "textContent": _vm._s("".concat(_vm.btnText, " ").concat(user.name))
        },
        on: {
          "click": function click($event) {
            return _vm.onListItemClick(user.id);
          }
        }
      });
    }), 1) : _vm._e()], 1)], 1);
  }), _vm.warnings.length ? _c('v-subheader', [_vm._v("Advarsler")]) : _vm._e(), _vm._l(_vm.warnings, function (errorItem, i) {
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "color": "primary"
      }
    }, [_vm._v("mdi-alert-box")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "capitalize"
    }, [_vm._v(_vm._s(errorItem.message))]), _vm.isUserButton ? _c('v-list-item-subtitle', {
      staticClass: "d-flex flex-row"
    }, _vm._l(errorItem.users, function (user, i) {
      return _c('v-btn', {
        key: i,
        attrs: {
          "small": "",
          "color": "primary"
        },
        domProps: {
          "textContent": _vm._s("".concat(_vm.btnText, " ").concat(user.name))
        },
        on: {
          "click": function click($event) {
            return _vm.onListItemClick(user.id);
          }
        }
      });
    }), 1) : _vm._e()], 1)], 1);
  })], 2)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }