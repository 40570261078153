
import { api } from "@/api/api";
import {
  ApiCloseCourseValidationCategoryDto,
  ApiCloseCourseValidationDto,
  ApiGetCourseDto,
  ApiGetCourseParticipantDto,
  ApiGetCurriculumDto,
} from "@/api/generated/Api";
import CourseCard from "@/components/course/details/CourseCard.vue";
import EndCourseExpansionPanel from "@/components/course/details/endCourse/endCourseExpansionPanel.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { EndCourseValidationStatus } from "@/shared/constants/endCourseValidationStatus";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { loadAllCourseData } from "@/shared/helpers/courseHelpers";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { getErrorCategoryColor, getErrorCategoryIcon } from "@/shared/helpers/endCourseHelper";
import { onParamChange } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, ref } from "@vue/composition-api";
import axios from "axios";

export enum EndCourseValidationCategories {
  Course = "course",
  ParticipantAttendance = "participantAttendance",
  Workinghour = "workinghour",
  WorkinghourAttendance = "workinghourAttendance",
  ParticipantExpense = "participantExpense",
  Economy = "economy",
  TeachingGrant = "teachinggrant",
}

enum AvailableBypasses {
  skipWorkingHours = "skipWorkingHours",
  skipTeachingHours = "skipTeachingHours",
  skipAmountOfParticipantsOlderThan14 = "skipAmountOfParticipantsOlderThan14",
}

export default defineComponent({
  name: "EndCoursePage",
  components: { BaseLayout, CourseCard, EndCourseExpansionPanel },
  setup() {
    const store = useStore<StoreState>();
    const route = useRoute();
    const router = useRouter();
    const courseId = +route.params.id;
    const course = ref<ApiGetCourseDto>();
    const courseParticipantStudents = ref<ApiGetCourseParticipantDto[]>([]);
    const curriculum = ref<ApiGetCurriculumDto>();
    const contentReady = ref(false);
    const openExpansionPanel = ref(0);
    const validationErrors = ref<ApiCloseCourseValidationDto>();
    const closeParticipantErrors = ref<string[]>([]);
    const skipWorkingHours = ref<boolean>(false);
    const skipAmountOfParticipantsOlderThan14 = ref<boolean>(false);
    const skipTeacherHours = ref<boolean>(false);

    const getCategoryErrors = (category: EndCourseValidationCategories) =>
      validationErrors.value?.categories?.find((item: ApiCloseCourseValidationCategoryDto) => item.name === category);

    const loadCourse = async () => {
      ({
        course: course.value,
        courseParticipantStudents: courseParticipantStudents.value,
        studyplan: curriculum.value,
      } = await loadAllCourseData(courseId));
      await validateCourse();
    };

    const closeCourse = async () => {
      try {
        await api.course.closeCourseAsync(courseId, {
          skipWorkingHours: skipWorkingHours.value,
          skipAmountOfParticipantsOlderThan14: skipAmountOfParticipantsOlderThan14.value,
          skipTeacherHours: skipTeacherHours.value,
        });
        openNotification(store, NotificationItemType.Success, "Kurset er nå avsluttet");
        router.push({ name: SingleCourseRouteNames.CourseDashboard, params: { id: courseId.toString() } });
      } catch (err: unknown) {
        formatCloseCourseResponse(err);
      }
    };

    const formatCloseCourseResponse = (err: unknown) => {
      if (!axios.isAxiosError(err) || !err.response) {
        return;
      }
      validationErrors.value = err.response.data as ApiCloseCourseValidationDto;
    };

    const validateCourse = async () => {
      try {
        validationErrors.value = (await api.course.validateCloseCourseAsync(courseId)).data;
      } catch (err: unknown) {
        formatCloseCourseResponse(err);
      } finally {
        contentReady.value = true;
      }
    };

    const navigateToAttendance = () => {
      router.push({ name: SingleCourseRouteNames.CourseAttendance, params: { id: route.params.id } });
    };

    const navigateToParticipant = (id: number) => {
      router.push({
        name: SingleCourseRouteNames.CourseParticipantDetails,
        params: { id: route.params.id, participantId: id.toString() },
      });
    };

    onParamChange(loadCourse);

    const availableBypasses = computed(() => validationErrors.value?.availableBypasses);

    const hasAmountOfParticipantsOlderThan14Bypass = computed(() =>
      availableBypasses.value?.includes(AvailableBypasses.skipAmountOfParticipantsOlderThan14)
    );

    const hasTeachingHoursBypass = computed(() =>
      availableBypasses.value?.includes(AvailableBypasses.skipTeachingHours)
    );

    const hasWorkingHoursBypass = computed(() => availableBypasses.value?.includes(AvailableBypasses.skipWorkingHours));

    return {
      course,
      courseParticipantStudents,
      curriculum,
      contentReady,
      openExpansionPanel,
      navigateToAttendance,
      navigateToParticipant,
      validationErrors,
      closeParticipantErrors,
      getCategoryErrors,
      getErrorCategoryColor,
      getErrorCategoryIcon,
      closeCourse,
      EndCourseValidationCategories,
      EndCourseValidationStatus,
      isVocationalSchool: computed(() => isVocationalSchool(curriculum.value?.mainCourseId)),
      hasRegisterHours: computed(() => course.value?.plan?.registerHours),
      hasSchedule: computed(() => course.value?.plan?.schedules && course.value.plan.schedules.length > 0),
      allowExpenses: computed(() => course.value?.economy?.allowExpenses),
      skipWorkingHours,
      skipTeacherHours,
      skipAmountOfParticipantsOlderThan14,
      hasTrainingGrant: computed(() => course.value?.economy?.grantId === 10 || course.value?.economy?.grantId === 20),
      hasAmountOfParticipantsOlderThan14Bypass,
      hasTeachingHoursBypass,
      hasWorkingHoursBypass,
    };
  },
});
