var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expansion-panels', {
    attrs: {
      "mandatory": "",
      "flat": "",
      "outline": ""
    },
    model: {
      value: _vm.expansionPanels,
      callback: function callback($$v) {
        _vm.expansionPanels = $$v;
      },
      expression: "expansionPanels"
    }
  }, [_c('v-expansion-panel', {
    staticClass: "pb-4 mt-0",
    attrs: {
      "active-class": "pb-0"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-expansion-panel-header', {
    staticClass: "px-4 py-2"
  }, [_c('span', {
    staticClass: "subtitle-1"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-information-outline")]), _vm._v("Generell informasjon")], 1)]), _c('v-expansion-panel-content', [_c('v-row', _vm._l(_vm.courseInformationCard, function (courseInfoItem, index) {
    return _c('v-col', {
      key: index,
      staticClass: "pa-0",
      attrs: {
        "cols": "6"
      }
    }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-subtitle', {
      attrs: {
        "data-cy": "courseCardItemTitle-".concat(index)
      }
    }, [_vm._v(_vm._s(courseInfoItem.title))]), _c('v-list-item-title', {
      attrs: {
        "data-cy": "courseCardItemValue-".concat(index)
      }
    }, [_vm._v(_vm._s(courseInfoItem.value))])], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1), _c('v-expansion-panel', {
    staticClass: "pb-4 mt-0",
    attrs: {
      "active-class": "pb-0"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-expansion-panel-header', {
    staticClass: "px-4 py-2"
  }, [_c('span', {
    staticClass: "subtitle-1"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-clock-outline")]), _vm._v("Timer")], 1)]), _c('v-expansion-panel-content', [_c('v-row', _vm._l(_vm.hoursInformationCard, function (hoursInfoItem, index) {
    return _c('v-col', {
      key: index,
      staticClass: "pa-0",
      attrs: {
        "cols": "6"
      }
    }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-subtitle', {
      attrs: {
        "data-cy": "courseCardItemTitle-".concat(index)
      }
    }, [_vm._v(_vm._s(hoursInfoItem.title))]), _c('v-list-item-title', {
      attrs: {
        "data-cy": "courseCardItemValue-".concat(index)
      }
    }, [_vm._v(_vm._s(hoursInfoItem.value))])], 1)], 1)], 1);
  }), 1), _vm.course.plan.schedules && _vm.validationState ? _c('ScheduleBanners', {
    attrs: {
      "schedules": _vm.course.plan.schedules,
      "hoursMax": _vm.maxHours,
      "hoursWithInstructor": _vm.course.hoursWithInstructor,
      "validationState": _vm.validationState
    }
  }) : _vm._e()], 1)], 1)], 1), _c('v-expansion-panel', {
    staticClass: "pb-4 mt-0",
    attrs: {
      "active-class": "pb-0"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-expansion-panel-header', {
    staticClass: "px-4 py-2"
  }, [_c('span', {
    staticClass: "subtitle-1"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-information-outline")]), _vm._v("Detaljer")], 1)]), _c('v-expansion-panel-content', [_c('v-row', _vm._l(_vm.detailsCard, function (detailsItem, index) {
    return _c('v-col', {
      key: index,
      staticClass: "pa-0",
      attrs: {
        "cols": "6"
      }
    }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-subtitle', {
      attrs: {
        "data-cy": "detailsCardItemTitle-".concat(index)
      }
    }, [_vm._v(" " + _vm._s(detailsItem.title) + " ")]), _c('v-list-item-subtitle', {
      attrs: {
        "data-cy": "detailsCardItemValue-".concat(index)
      }
    }, [_vm._v(" " + _vm._s(detailsItem.value) + " ")])], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1), _c('v-expansion-panel', {
    staticClass: "pb-4 mt-0",
    attrs: {
      "active-class": "pb-0"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-expansion-panel-header', {
    staticClass: "px-4 py-2"
  }, [_c('span', {
    staticClass: "subtitle-1"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-cash-multiple")]), _vm._v("Økonomi")], 1)]), _c('v-expansion-panel-content', [_c('v-row', _vm._l(_vm.economyInformationCard, function (detailsItem, index) {
    return _c('v-col', {
      key: index,
      staticClass: "pa-0",
      attrs: {
        "cols": "6"
      }
    }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(detailsItem.title) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(detailsItem.value) + " ")])], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }